export type CardState = "UNKNOWN" | "KNOWN" | "SUCCESS" | "SKIP"

export interface Card {
    id: number,
    front: string,
    back: string,
    state: CardState,
    numTries: number,
    numSuccess: number,
    numRemaining?: number
}

export interface Pool {
    id: number,
    level: number,
    name: string,
    description: string
    cards: Card[]
}

let pools: Pool[] = []

export const setPools = function (data: Pool[]) {
    pools = data
}

export const getPool = function (id: number): Pool {
    return pools.filter(value => value.id === id)[0];
}
export const getPools = function (): Pool[] {
    return pools;
}

export const BACKEND = "/api/";