import React from "react";

interface ToggleButtonProps {
    text: string
    pushed: boolean
    onChange: ((changed: boolean) => void)
}

interface ToggleButtonState {
    pushed: boolean
}

export class ToggleButton extends React.Component<ToggleButtonProps, ToggleButtonState> {
    constructor(props: Readonly<ToggleButtonProps>) {
        super(props);
        this.state = {pushed: props.pushed}
    }

    render() {
        let classes = "toggleButton";
        if (this.state.pushed) {
            classes += " pushed";
        }
        return <>
            <div className={classes} onClick={event => this.onChange(event)}>
                {this.props.text}
            </div>
        </>;
    }

    private onChange(event: React.MouseEvent<HTMLDivElement>) {
        const oldState = !this.state.pushed
        this.setState({pushed: oldState});
        this.props.onChange(oldState);
    }
}