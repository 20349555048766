import React from "react";
import {FetchCards, fetchCards} from "../../backend/FetchCards";
import {PoolViewCard} from "./PoolViewCard";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import {PixmapButton} from "../Pixmap";

interface PoolViewCardsProps {
    id: string
}

interface PoolViewCardsState {
    fetched: FetchCards
    page: number
    back: boolean
}

class PoolViewCardsClazz extends React.Component<RouteComponentProps<PoolViewCardsProps>, PoolViewCardsState> {
    constructor(props: Readonly<RouteComponentProps<PoolViewCardsProps>>) {
        super(props);
        this.state = {fetched: {total: 0, offset: 0, cards: []}, page: 0, back: false}
    }

    componentDidMount() {
        this.loadPage(0);
    }

    render() {
        if (this.state.back) {
            return <Redirect to={"/pool/" + this.props.match.params.id}/>
        }
        let end = this.state.fetched.offset + this.state.fetched.cards.length;
        let isFront = this.state.fetched.offset === 0;
        let isBack = end === this.state.fetched.total;

        let cards = this.state.fetched.cards.map(card => {
            return (<PoolViewCard pool={this.props.match.params.id} card={card}
                                  onCardRemoved={() => this.reloadPage()}/>);
        })

        let loading;
        if (this.state.fetched.total === 0) {
            loading = "Lade Karten..."
        }


        let pagination = (
            <div className="poolViewPagination">
                <span>Zeige Karten {this.state.fetched.offset} bis {end} von {this.state.fetched.total}. {loading}</span>
                <br/>
                <PixmapButton onClick={() => this.loadPrevPage()} disabled={isFront} pixmap="back"
                              text="Vorherige Seite"/>
                <PixmapButton onClick={() => this.loadNextPage()} disabled={isBack} pixmap="forward"
                              text="Nächste Seite"/>
            </div>
        )
        return (
            <div className="pageContent">
                <div className="pageHeader">
                    <PixmapButton onClick={(event) => this.back()} pixmap="back" text="Zurück zur Lektion"/>
                </div>
                {pagination}
                {cards}
                {pagination}
                <div className="pageFooter">
                    <PixmapButton onClick={(event) => this.back()} pixmap="back" text="Zurück zur Lektion"/>
                </div>
                <br/>
            </div>
        )
    }

    private reloadPage() {
        this.loadPage(this.state.page);
    }

    private loadPage(page: number) {
        if (page < 0) {
            return;
        }
        fetchCards(this.props.match.params.id, page).then(r => this.setState({fetched: r, page: page}))
    }

    private loadNextPage() {
        this.loadPage(this.state.page + 1);
    }

    private loadPrevPage() {
        this.loadPage(this.state.page - 1);
    }

    private back() {
        this.setState({back: true})
    }
}

export const PoolViewCards = withRouter(PoolViewCardsClazz);