import {BACKEND} from "../interfaces/types";

export function addCard(contentFront: string, contentBack: string, pool: string): Promise<Response> {
    let formData = new URLSearchParams();
    formData.append("front", contentFront);
    formData.append("back", contentBack);

    return fetch(BACKEND + "pool/" + pool + "/add", {
        method: 'POST',
        body: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include'
    })
}