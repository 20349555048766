import React from "react";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import {RichTextEditor} from "../RichTextEditor";
import {addCard} from "../../backend/AddCard";
import {PixmapButton} from "../Pixmap";

type PoolAddCardProps = {
    id: string
};

interface PoolAddCardState {
    saving: "NOTSTARTED" | "SAVING" | "SAVED" | "ERROR"
}


class PoolAddCardClazz extends React.Component<RouteComponentProps<PoolAddCardProps>, PoolAddCardState> {
    frontRef: React.RefObject<RichTextEditor>;
    backRef: React.RefObject<RichTextEditor>;

    constructor(props: Readonly<RouteComponentProps<PoolAddCardProps>>) {
        super(props);

        this.frontRef = React.createRef();
        this.backRef = React.createRef()
        this.state = {saving: "NOTSTARTED"}
    }

    render() {
        if (this.state.saving === "SAVED") {
            return <Redirect to={"/pool/" + this.props.match.params.id}/>
        }

        let saveHint;
        if (this.state.saving === "ERROR") {
            saveHint = "Speichern Fehlgeschlagen"
        } else if (this.state.saving === "SAVING") {
            saveHint = "Speichern... Bitte warten"
        }

        return (
            <div className="pageContent">
                <div className="pageHeader">
                    <PixmapButton onClick={event => this.back()} pixmap="back" text="Zurück zur Lektion"/>
                </div>
                <div className="card">
                    <div className="cardFront editor">
                        <RichTextEditor ref={this.frontRef}/>
                    </div>
                    <div className="cardBack editor">
                        <RichTextEditor ref={this.backRef}/>
                    </div>
                    <div className="cardFooter">
                        <PixmapButton onClick={event => this.create(event, false)} pixmap="save"
                                      text="Anlegen & Zurück zur Lektion"/>
                        <PixmapButton onClick={event => this.create(event, true)} pixmap="add"
                                      text="Noch eine Karte anlegen"/>
                        {saveHint}
                    </div>
                </div>
                <div className="pageFooter">
                    <PixmapButton onClick={event => this.back()} pixmap="back" text="Zurück zur Lektion"/>
                </div>
            </div>
        )
    }

    private create(event: React.MouseEvent<HTMLButtonElement>, createAnother: boolean) {
        let contentFront = this.frontRef.current!.getHTML();
        let contentBack = this.backRef.current!.getHTML();

        this.setState({saving: "SAVING"})
        addCard(contentFront, contentBack, this.props.match.params.id)
            .catch(reason => {
                console.log(reason);
                return {ok: false} as Response
            })
            .then(response => {
                if (response.ok) {
                    if (createAnother) {
                        this.frontRef.current!.reset();
                        this.backRef.current!.reset();
                        this.setState({saving: "NOTSTARTED"})
                    } else {
                        this.setState({saving: "SAVED"})
                    }
                } else {
                    this.setState({saving: "ERROR"})
                }
            });
    }

    private back() {
        this.setState({saving: "SAVED"});
    }
}

export const PoolAddCard = withRouter(PoolAddCardClazz);