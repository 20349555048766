import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import React from "react";
import {Card, CardState} from "../../interfaces/types";
import {loadCurrentCard} from "../../backend/training/CurrentCard";
import {advanceCard} from "../../backend/training/AdvanceCard";
import {PixmapButton, PixmapGroup} from "../Pixmap";
import {GlobalKeyObservable} from "../../interfaces/state";

interface TrainDoProps {
    id: string
}

interface TrainDoState {
    card: Card
    showBack: boolean
    done: boolean,
}

class TrainOverviewClazz extends React.Component<RouteComponentProps<TrainDoProps>, TrainDoState> {
    constructor(props: Readonly<RouteComponentProps<TrainDoProps>>) {
        super(props);
        this.state = {
            card: {id: 0, front: "", back: "", state: "UNKNOWN", numTries: 0, numSuccess: 0},
            showBack: false,
            done: false,
        }
    }

    componentDidMount() {
        this.loadCard();
        GlobalKeyObservable.subscribe(this.globalKeyPressListener)
    }

    componentWillUnmount() {
        GlobalKeyObservable.unsubscribe(this.globalKeyPressListener)
    }


    globalKeyPressListener = (t: KeyboardEvent) => {
        if (this.state.showBack) {
            if (t.key === "v" || t.key === "1") {
                this.advanceCard("SUCCESS")
            } else if (t.key === "b" || t.key === "2") {
                this.advanceCard("KNOWN")
            } else if (t.key === "n" || t.key === "3") {
                this.advanceCard("UNKNOWN")
            }
        } else {
            if (t.key === " " || t.key === "0") {
                this.setState({showBack: true})
            }
        }

        if (t.key === "z" || t.key === "8") {
            this.advanceCard("SKIP")
        }
    }

    render() {
        if (this.state.done) {
            return <Redirect to={"/pool/" + this.props.match.params.id}/>
        }
        var showBack;
        if (this.state.showBack) {
            showBack = (
                <>
                    <div className="cardBack">
                        <div className="inner">
                            <div dangerouslySetInnerHTML={{__html: this.state.card.back || ""}}>{}</div>
                        </div>
                    </div>
                    <div className="cardFooter">
                        <PixmapGroup>
                            <PixmapButton onClick={(event) => this.advanceCard("SUCCESS")} pixmap="check"
                                          text="Korrekt"/>
                            <PixmapButton onClick={(event) => this.advanceCard("KNOWN")} pixmap="cancel"
                                          text="Bekannt, aber falsch"/>
                            <PixmapButton onClick={(event) => this.advanceCard("UNKNOWN")} pixmap="question"
                                          text="Unbekannt"/>
                        </PixmapGroup>
                    </div>
                </>
            )
        } else {
            showBack = (
                <div className="cardBack">
                    <div className="inner">
                        <PixmapButton onClick={(event) => this.showBack(event)} pixmap="forward"
                                      text="Zeige Rückseite"/>
                    </div>
                </div>
            )
        }

        return (
            <div className="pageContent">
                <div className="pageHeader">
                    <PixmapButton onClick={(event) => this.back()} pixmap="back" text="Zurück zur Lektion"/>
                    <div className="remainingCards">
                        {this.state.card.numRemaining || 0} Karten verbleibend
                    </div>
                </div>

                <div className="card">
                    <div className="cardFront">
                        <div className="inner">
                            <div dangerouslySetInnerHTML={{__html: this.state.card.front || ""}}>{}</div>
                        </div>
                    </div>
                    {showBack}
                </div>

                <div className="pageFooter">
                    <PixmapButton onClick={(event) => this.back()} pixmap="back" text="Zurück zur Lektion"/>
                    <div className="remainingCards">
                        {this.state.card.numRemaining || 0} Karten verbleibend
                    </div>
                </div>
            </div>
        )
    }

    loadCard() {
        loadCurrentCard().then(value => this.setState({card: value, showBack: false}))
    }

    private showBack(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({showBack: true})
    }

    private advanceCard(state: CardState) {
        advanceCard(state).then(data => parseInt(data)).then(data => data > 0 ? this.loadCard() : this.back());
    }

    private back() {
        this.setState({done: true})
    }
}

export const TrainDo = withRouter(TrainOverviewClazz);