import React, {Component} from "react";
import {Redirect, Route, RouteComponentProps, RouteProps, withRouter} from "react-router-dom";
import {login, logout} from "../backend/FetchLogin";
import {LoggedInObservable} from "../interfaces/state";
import {PixmapButton} from "./Pixmap";
import {CheckLogin} from "../backend/CheckLogin";

interface LoginProps {
    location: any
}

interface LoginState {
    loginError: boolean
    redirectToReferrer: boolean
    username: string,
    password: string
}

export class Login extends React.Component<LoginProps, LoginState> {
    state = {
        loginError: false,
        redirectToReferrer: false,
        username: "",
        password: ""
    }
    login = () => {
        login(this.state.username, this.state.password)
            .catch(reason => {
                this.setState({loginError: true})
                return {loggedIn: false, username: this.state.username} as CheckLogin
            })
            .then(value => {
                LoggedInObservable.setValue(value);
                if (value.loggedIn) {
                    this.setState(() => ({
                        loginError: false,
                        redirectToReferrer: true
                    }))
                } else {
                    this.setState({loginError: true})
                }
            })
    }

    listener = (value: CheckLogin) => {
        console.log("Called Login listener with value", value)
        this.setState({redirectToReferrer: value.loggedIn});
    }

    componentDidMount() {
        LoggedInObservable.subscribe(this.listener);
    }

    componentWillUnmount() {
        LoggedInObservable.unsubscribe(this.listener);
    }

    render() {
        const {from} = this.props.location.state || {from: {pathname: '/'}}
        const {redirectToReferrer} = this.state

        if (redirectToReferrer) {
            return <Redirect to={from}/>
        }

        let errorMessage;
        if (this.state.loginError) {
            errorMessage = (
                <span className='error'>Anmeldung fehlgeschlagen.</span>
            );
        }

        return (
            <div className="pageContent">
                {errorMessage}
                <form target="#" onSubmit={event => this.handleSubmit(event)}>
                    <p>
                        <label htmlFor="username">Name:</label><br/>
                        <input name="username" type="text"
                               onChange={event => this.setState({username: event.target.value})}/>
                    </p>
                    <p>
                        <label htmlFor="password">Passwort:</label> <br/>
                        <input name="password" type="password"
                               onChange={event => this.setState({password: event.target.value})}/>
                    </p>
                    <PixmapButton onClick={event => this.login()} pixmap="login" text="Anmelden"/>
                </form>
            </div>
        )
    }

    private handleSubmit(event: React.FormEvent) {
        this.login();
        event.preventDefault();
    }
}

function WrapComponent(Component: React.ComponentType<any> | any) {
    return (<Component/>)
}

interface PrivateRouteState {
    loggedIn: boolean
}

export class PrivateRoute extends Component<RouteProps, PrivateRouteState> {

    constructor(props: Readonly<RouteProps>) {
        super(props);
        this.state = {loggedIn: false};
    }

    listener = (value: CheckLogin) => {
        console.log("Called PrivateRoute listener with value", value)
        this.setState({loggedIn: value.loggedIn});
    }

    componentDidMount() {
        LoggedInObservable.subscribe(this.listener);
    }

    componentWillUnmount() {
        LoggedInObservable.unsubscribe(this.listener);
    }

    render() {
        console.log("Rendering PrivateRoute with", this.state)
        const {component, ...rest} = this.props;
        return (
            <Route
                {...rest}
                render={props =>
                    this.state.loggedIn
                        ? WrapComponent(component)
                        : <Redirect to="/login"/>
                }
            />
        );
    }
}

class AuthButtonClazz extends React.Component<RouteComponentProps<any>, CheckLogin> {
    constructor(props: Readonly<RouteComponentProps<any>>) {
        super(props);
        this.state = {loggedIn: false, username: ""}
    }

    render() {
        if (this.state.loggedIn) {
            return (
                <>
                    <span>Wilkommen {this.state.username}!</span>
                    <div className="inner">
                        <PixmapButton onClick={event => this.logout()} pixmap="exit" text="Abmelden"/>
                    </div>
                </>
            );
        }
        return <span>You are not logged in.</span>
    }

    private logout() {
        logout().then(value => {
            LoggedInObservable.setValue({loggedIn: false, username: ""});
            this.props.history.push('/');
        })
    }

    componentDidMount() {
        LoggedInObservable.subscribe(this.listener);
    }

    componentWillUnmount() {
        LoggedInObservable.unsubscribe(this.listener);
    }

    listener = (value: CheckLogin) => {
        console.log("Called AuthButtonClazz listener with value", value)
        this.setState(value);
    }
}

export const AuthButton = withRouter(AuthButtonClazz);