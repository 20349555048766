import React from "react";
import {PoolOverviewEntry} from "./PoolOverviewEntry"
import {BACKEND, getPools, setPools} from "../../interfaces/types";
import {PixmapButton} from "../Pixmap";
import {Redirect} from "react-router-dom";


interface PoolOverviewProps {
}

interface PoolOverviewState {
    addNew: boolean
}

export class PoolOverview extends React.Component<PoolOverviewProps, PoolOverviewState> {
    constructor(props: Readonly<PoolOverviewProps>) {
        super(props);
        this.state = {addNew: false}
    }

    componentDidMount() {
        fetch(BACKEND + "pools").then(value => value.json()).then(value => {
            setPools(value);
            this.forceUpdate(() => {
            })
        })
    }

    render() {
        if (this.state.addNew) {
            return <Redirect to="/pools/new"/>
        }

        const pools = getPools();
        return (
            <div className="pageContent">
                <h1>Lektionen:</h1>
                <ol>
                    {
                        pools.map(pool => PoolOverviewEntry(pool))
                    }
                </ol>

                <div className="pageFooter">
                    <PixmapButton onClick={event => this.setState({addNew: true})} pixmap="add"
                                  text="Neue Lektion hinzufügen"/>
                </div>
            </div>
        );
    }
}