import React from "react";
import {Pool} from "../../interfaces/types";
import {Link} from "react-router-dom";

export function PoolOverviewEntry(pool: Pool) {
    let url = "/pool/" + pool.id;
    let indention = "\u00A0".repeat(4*pool.level);
    return (
        <li>
            <Link to={url}>{indention}{pool.name}</Link>
        </li>
    )
}