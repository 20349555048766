import {Order} from "../../components/train/TrainOverview";
import {BACKEND} from "../../interfaces/types";

export enum InitializationResult {
    SUCCESS,
    NO_USAGE_SELECTED,
    NO_CARDS_AVAILABLE,
    UNKNOWN_FAILURE
}

export function initializeTraining(poolId: string, order: Order, useUnknown: boolean, useKnown: boolean, useSuccessful: boolean): Promise<InitializationResult> {
    let formData = new URLSearchParams();
    formData.append("pool", poolId);
    formData.append("order", "" + order);
    formData.append("unknown", "" + useUnknown);
    formData.append("known", "" + useKnown);
    formData.append("successful", "" + useSuccessful);

    return fetch(BACKEND + "training/initialize", {
        method: 'POST',
        body: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include'
    }).then(value => value.text()).then(value => parseEnum(value));
}

export function reinitializeTraining(): Promise<InitializationResult> {
    return fetch(BACKEND + "training/reinitialize", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include'
    }).then(value => value.text()).then(value => parseEnum(value));
}

function parseEnum(fromBackend: string): InitializationResult {
    if (fromBackend in InitializationResult) {
        return InitializationResult[fromBackend as keyof typeof InitializationResult];
    }
    return InitializationResult.UNKNOWN_FAILURE;
}