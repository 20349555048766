import React from "react";
import Quill from "quill"
import Delta from "quill-delta";

type RichTextEditorProps = {
    content?: string
};


interface RichTextEditorState {
    quill: Quill | null
}

export class RichTextEditor extends React.Component<RichTextEditorProps, RichTextEditorState> {
    private ref: React.RefObject<HTMLDivElement>;
    private wrapperRef: React.RefObject<HTMLDivElement>;

    constructor(props: Readonly<RichTextEditorProps>) {
        super(props);
        this.state = {quill: null}
        this.ref = React.createRef();
        this.wrapperRef = React.createRef();
    }

    render() {
        return (
            <div ref={this.wrapperRef}>
                <div ref={this.ref} dangerouslySetInnerHTML={{__html:this.props.content || "" }}>{}</div>
            </div>
        )
    }

    componentDidMount() {
        this.setState({
            quill: new Quill(this.ref.current!, {theme: "snow"})
        })
    }

    componentWillUnmount() {
        this.state.quill!.disable();
        this.wrapperRef.current!.childNodes.forEach(value => {
            this.wrapperRef.current!.removeChild(value);
        })
    }

    public getHTML(): string {
        return this.state.quill!.root.innerHTML;
    }

    public reset() {
        this.state.quill!.setContents(new Delta());
    }
}