import {BACKEND} from "../interfaces/types";

export interface CheckLogin {
    loggedIn: boolean;
    username: string
}

export function checkLogin(): Promise<CheckLogin> {
    return fetch(BACKEND + "user/", {
        method: "GET",
        credentials: 'include'
    })
        .then(value => value.json())
}