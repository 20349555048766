import {BACKEND} from "../interfaces/types";

export function editCard(pool: string, contentFront: string, contentBack: string, cardId: number): Promise<Response> {
    let formData = new URLSearchParams();
    formData.append("id", ""+cardId);
    formData.append("front", contentFront);
    formData.append("back", contentBack);

    return fetch(BACKEND + "pool/" + pool + "/edit/do", {
        method: 'POST',
        body: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include'
    })
}