import React from "react";
import {RouteComponentProps, Redirect} from "react-router-dom";
import {RichTextEditor} from "../RichTextEditor";
import {getPools, Pool} from "../../interfaces/types";
import {createPool} from "../../backend/CreatePool";
import {PixmapButton} from "../Pixmap";

type PoolAddCardProps = {
    id: string
};

interface PoolAddCardState {
    name: string;
    parent: string;
    created: boolean;
}

function PoolAddOption(pool: Pool) {
    let indention = "\u00A0".repeat(4 * pool.level);

    return (
        <option value={pool.id}>{indention}{pool.name}</option>
    )
}

export class PoolAdd extends React.Component<RouteComponentProps<PoolAddCardProps>, PoolAddCardState> {
    ref: React.RefObject<RichTextEditor>;

    constructor(props: Readonly<RouteComponentProps<PoolAddCardProps>>) {
        super(props);

        this.ref = React.createRef();
        this.state = {name: "", parent: "", created: false}
    }

    render() {
        if (this.state.created) {
            return <Redirect to="/pools"/>
        }
        return (
            <div className="pageContent">
                <div className="pageHeader">
                    <PixmapButton onClick={(event) => this.back()} pixmap="back" text="Zurück zur Übersicht"/>
                </div>
                <p>
                    <label htmlFor="name">Name:</label><br/>
                    <input type="text" name="name" onChange={event => this.setState({name: event.target.value})}/>
                </p>
                <p>
                    <label htmlFor="description">Beschreibung:</label><br/>
                    <RichTextEditor ref={this.ref}/>
                </p>
                <p>
                    <label htmlFor="parent">Unterhalb von:</label> <br/>
                    <select name="parent" onChange={event => this.setState({parent: event.target.value})}>
                        <option value="-1">Keiner</option>
                        {
                            getPools().map(value => PoolAddOption(value))
                        }
                    </select>
                </p>
                <PixmapButton onClick={event => this.submit(event)} pixmap="add" text="Anlegen"/>
                <div className="pageFooter">
                    <PixmapButton onClick={(event) => this.back()} pixmap="back" text="Zurück zur Übersicht"/>
                </div>
            </div>
        )
    }

    private submit(event: React.MouseEvent<HTMLButtonElement>) {
        createPool(this.state.name, this.ref.current!.getHTML(), this.state.parent).then(value => {
            console.log(value)
            this.setState({created: value.ok})
        });
    }

    private back() {
        this.setState({created: true});
    }
}