import React from "react";
import {Card, CardState} from "../../interfaces/types";
import {RichTextEditor} from "../RichTextEditor";
import {editCard} from "../../backend/EditCard";
import {deleteCard} from "../../backend/DeleteCard";
import {PixmapButton} from "../Pixmap";

interface PoolViewCardProps {
    pool: string,
    card: Card

    onCardRemoved(): any
}

interface PoolViewCardState {
    showEditor: boolean
    front: string,
    back: string,
}

export class PoolViewCard extends React.Component<PoolViewCardProps, PoolViewCardState> {
    frontRef: React.RefObject<RichTextEditor>;
    backRef: React.RefObject<RichTextEditor>;

    constructor(props: Readonly<PoolViewCardProps>) {
        super(props);
        this.frontRef = React.createRef();
        this.backRef = React.createRef();

        this.state = {showEditor: false, front: this.props.card.front, back: this.props.card.back}
    }

    componentDidUpdate(prevProps: Readonly<PoolViewCardProps>, prevState: Readonly<PoolViewCardState>, snapshot?: any) {
        if (this.props.card.front !== prevProps.card.front || this.props.card.back !== prevProps.card.back) {
            this.setState({showEditor: false, front: this.props.card.front, back: this.props.card.back});
        }
    }

    render() {
        let cardFront = this.state.showEditor ?
            <RichTextEditor ref={this.frontRef} content={this.state.front}>{}</RichTextEditor> :
            <div className="inner" dangerouslySetInnerHTML={{__html: this.state.front}}/>;
        let cardBack = this.state.showEditor ?
            <RichTextEditor ref={this.backRef} content={this.state.back}>{}</RichTextEditor> :
            <div className="inner" dangerouslySetInnerHTML={{__html: this.state.back}}/>;

        let state = PoolViewCard.prettifyName(this.props.card.state);
        console.log(this.props.card.state, state)

        let frontClasses = "cardFront";
        let backClasses = "cardBack";

        if (this.state.showEditor) {
            frontClasses += " editor"
            backClasses += " editor"
        }

        return (
            <div className="card">
                <div className="cardHeading">Status: {state}, Versuche: {this.props.card.numTries}, Erfolgreich
                    seit {this.props.card.numSuccess} Versuchen
                </div>
                <div className={frontClasses} onClick={() => this.showEditor()}>
                    {cardFront}
                </div>
                <div className={backClasses} onClick={() => this.showEditor()}>
                    {cardBack}
                </div>
                {this.renderShowEditorButtons()}
            </div>
        )
    }

    private renderShowEditorButtons() {
        if (this.state.showEditor) {
            return (
                <div className="cardFooter">
                    <PixmapButton onClick={() => this.save()} pixmap="save" text="Speichern"/>
                    <PixmapButton onClick={() => this.hideEditor()} pixmap="cancel" text="Abbrechen"/><br/>
                    <PixmapButton onClick={() => this.deleteCard()} pixmap="delete" text="Löschen"/>
                </div>
            );
        }
        return <></>;
    }

    private showEditor() {
        this.setState({showEditor: true})
    }

    private hideEditor() {
        this.setState({showEditor: false})
    }

    private save() {
        let newFront = this.frontRef.current!.getHTML()
        let newBack = this.backRef.current!.getHTML()
        editCard(this.props.pool, newFront, newBack, this.props.card.id).then(
            r => {
                this.setState({
                    front: newFront,
                    back: newBack,
                    showEditor: false
                });
            }
        )
    }

    private deleteCard() {
        deleteCard(this.props.pool, this.props.card.id).then(r => this.props.onCardRemoved())
    }

    private static prettifyName(state: CardState): string {
        switch (state) {
            case "UNKNOWN":
                return "Unbekannt";
            case "KNOWN":
                return "Bekannt, aber falsch";
            case "SUCCESS":
                return "Erfolgreich";
            case "SKIP":
                return "Überspringen";
        }
    }
}