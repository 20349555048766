import React from "react";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import {poolDetails, PoolDetailsState} from "../../backend/PoolDetails";
import {PixmapButton, PixmapLink} from "../Pixmap";

type PoolDetailsProps = {
    id: string
}


class PoolDetailsClazz extends React.Component<RouteComponentProps<PoolDetailsProps>, PoolDetailsState> {
    private poolId: number;

    constructor(props: Readonly<RouteComponentProps<PoolDetailsProps>>) {
        super(props);
        this.state = {
            name: "",
            parent: 0,
            description: "",
            numCards: 0,
            numSuccessful: 0,
            numKnown: 0,
            numUnknown: 0,
            back: false
        }
        this.poolId = parseInt(this.props.match.params.id);
    }

    componentDidMount() {
        poolDetails(this.poolId).then(value => this.setState(value))
    }

    render() {
        if (this.state.back) {
            return <Redirect to={"/pools"}/>
        }
        return (
            <div className="pageContent">
                <div className="pageHeader">
                    <PixmapButton onClick={(event) => this.back()} pixmap="back" text="Zurück zur Übersicht"/>
                </div>

                <h1>Lektion: {this.state.name}</h1>

                <h2>Beschreibung:</h2>

                <p>
                    <i dangerouslySetInnerHTML={{__html: this.state.description || ""}}>{}
                    </i>
                </p>

                <h2>Karten:</h2>
                <b>{this.state.numCards}</b>, davon
                <ul>
                    <li className="listLabelSuccessful"><b>{this.state.numSuccessful}</b> erfolgreich</li>
                    <li className="listLabelKnown"><b>{this.state.numKnown}</b> bekannt</li>
                    <li className="listLabelUnknown"><b>{this.state.numUnknown}</b> unbekannt</li>
                </ul>

                <h2>Aktionen:</h2>

                <PixmapLink to={"/pool/" + this.poolId + "/train"} pixmap="brain" text="Lernen"/> <br/>
                <PixmapLink to={"/pool/" + this.poolId + "/view"} pixmap="look" text="Existierende Karten anschauen"/>
                <br/>
                <PixmapLink to={"/pool/" + this.poolId + "/add"} pixmap="add" text="Eine neue Karte hinzufügen"/> <br/>
                <PixmapLink to={"/pool/" + this.poolId + "/edit"} pixmap="edit" text="Lektion bearbeiten"/>
                <div className="pageFooter">
                    <PixmapButton onClick={(event) => this.back()} pixmap="back" text="Zurück zur Übersicht"/>
                </div>
            </div>
        )
    }

    private back() {
        this.setState({back: true})
    }
}

export const PoolDetails = withRouter(PoolDetailsClazz);