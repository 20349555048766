import React from "react";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import {RichTextEditor} from "../RichTextEditor";
import {getPools, Pool} from "../../interfaces/types";
import {poolDetails, PoolDetailsState} from "../../backend/PoolDetails";
import {editPool} from "../../backend/EditPool";
import {PixmapButton} from "../Pixmap";

type PoolEditProps = {
    id: string
};

interface PoolEditState {
    error: string;
    name: string;
    parent: string;
    description: string,
    saved: boolean;
}

function PoolEditOption(pool: Pool) {
    let indention = "\u00A0".repeat(4 * pool.level);

    return (
        <option value={pool.id}>{indention}{pool.name}</option>
    )
}

class PoolEditClazz extends React.Component<RouteComponentProps<PoolEditProps>, PoolEditState> {
    ref: React.RefObject<RichTextEditor>;
    private poolId: number;

    constructor(props: Readonly<RouteComponentProps<PoolEditProps>>) {
        super(props);

        this.ref = React.createRef();
        this.state = {error: "", name: "", description: "", parent: "", saved: false}
        this.poolId = parseInt(this.props.match.params.id);
    }

    componentDidMount() {
        poolDetails(this.poolId).then(value => this.fromDetails(value))
    }

    render() {
        if (this.state.saved) {
            return <Redirect to="/pools"/>
        }
        if (this.state.description === "") {
            return <> </>;
        }
        return (
            <div className="pageContent">
                <div className="pageHeader">
                    <PixmapButton onClick={(event) => this.back()} pixmap="back" text="Zurück zur Lektion"/>
                </div>
                <div>{this.state.error}</div>
                <p>
                    <label htmlFor="name">Name:</label><br/>
                    <input type="text" name="name" onChange={event => this.setName(event)}
                           value={this.state.name}/>
                </p>
                <p>
                    <label htmlFor="description">Beschreibung:</label><br/>
                    <RichTextEditor ref={this.ref} content={this.state.description}> </RichTextEditor>
                </p>
                <p>
                    <label htmlFor="parent">Unterhalb von:</label><br/>
                    <select name="parent" onChange={event => this.selectParent(event)}
                            value={this.state.parent}>
                        <option value="-1">Keiner</option>
                        {
                            getPools().map(value => PoolEditOption(value))
                        }
                    </select>
                </p>
                <PixmapButton onClick={event => this.submit(event)} pixmap="save" text="Speichern"/>

                <div className="pageFooter">
                    <PixmapButton onClick={(event) => this.back()} pixmap="back" text="Zurück zur Lektion"/>
                </div>
            </div>
        )
    }

    private setName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            name: event.target.value,
        });
    }

    private selectParent(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({
            parent: event.target.value,
        });
    }

    private submit(event: React.MouseEvent<HTMLButtonElement>) {
        editPool("" + this.poolId,
            this.state.name,
            this.ref.current!.getHTML(),
            this.state.parent)
            .then(value => {
                console.log(value)
                this.setState({saved: value.ok})
            }).catch(reason => this.setState({error: reason}));
    }

    private back() {
        this.setState({saved: true});
    }

    private fromDetails(value: PoolDetailsState) {
        this.setState({
            name: value.name,
            description: value.description,
            parent: "" + value.parent,
        })
    }
}

export const PoolEdit = withRouter(PoolEditClazz);