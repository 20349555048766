import {BACKEND, CardState} from "../../interfaces/types";

export function advanceCard(state: CardState): Promise<string> {
    let formData = new URLSearchParams();
    formData.append("state", state)

    return fetch(BACKEND + "training/next", {
        method: 'POST',
        body: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include'
    }).then(value => value.text());
}