import {BACKEND} from "../interfaces/types";

export interface PoolDetailsState {
    name: string,
    description: string,
    parent: number,
    numCards: number,
    numSuccessful: number,
    numKnown: number,
    numUnknown: number,
    back: boolean
}

export function poolDetails(poolId: number): Promise<PoolDetailsState> {
    return fetch(BACKEND + "pool/" + poolId + "/details", {
        method: 'GET',
        credentials: 'include'
    }).then(value => value.json())
}