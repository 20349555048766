import {BACKEND} from "../interfaces/types";

export function createPool(name: string, description: string, parent: string): Promise<Response> {
    let formData = new URLSearchParams();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("parent", parent);
    return fetch(BACKEND + "pool/new/do", {
        method: 'POST',
        body: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include'
    })
}