import {CheckLogin} from "../backend/CheckLogin";

type Listener<T> = (t: T) => void

class Observable<T> {
    private listeners: Listener<T>[] = [];
    private value: T;

    constructor(value: T) {
        this.value = value;
    }

    public subscribe(listener: Listener<T>) {
        this.listeners.push(listener);
    }

    public unsubscribe(listener: Listener<T>) {
        this.listeners = this.listeners.filter(value => value !== listener)
    }

    public setValue(value: T) {
        this.value = value;
        this.listeners.forEach(listener => listener.call(listener, this.value))
    }

    public getValue(): T {
        return this.value;
    }
}

export const LoggedInObservable = new Observable<CheckLogin>({loggedIn: false, username: ""});
export const GlobalKeyObservable = new Observable<KeyboardEvent>(new KeyboardEvent(""));
