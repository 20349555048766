import {BACKEND} from "../interfaces/types";

export function editPool(poolId: string, name: string, description: string, parent: string): Promise<Response> {
    let formData = new URLSearchParams();
    formData.append("poolId", poolId);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("parent", parent);
    return fetch(BACKEND + "pool/edit", {
        method: 'POST',
        body: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include'
    })
}