import {BACKEND} from "../interfaces/types";
import {CheckLogin} from "./CheckLogin";

export function login(username: string, password:string): Promise<CheckLogin> {
    let formData = new URLSearchParams();
    formData.append("username", username);
    formData.append("password", password);

    return fetch(BACKEND + "user/login", {
        method: 'POST',
        body: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    }).then(value => value.json());
}

export function logout(): Promise<Response> {
    let formData = new URLSearchParams();

    return fetch(BACKEND + "user/logout", {
        method: 'POST',
        body: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    }).then(value => value.json());
}