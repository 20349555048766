import {BACKEND, Card} from "../interfaces/types";

export interface FetchCards {
    total: number,
    offset: number,
    cards: Card[]
}

export function fetchCards(pool: string, page: number): Promise<FetchCards> {
    return fetch(BACKEND + "pool/" + pool + "/fetch/" + page, {
        method: 'GET',
        credentials: 'include'
    }).then(value => value.json())
}