import React, {MouseEventHandler} from "react";
import {Link} from "react-router-dom";

export type Pixmaps =
    "add"
    | "back"
    | "brain"
    | "cancel"
    | "check"
    | "delete"
    | "edit"
    | "exit"
    | "forward"
    | "login"
    | "look"
    | "question"
    | "save";

interface PixmapProps {
    pixmap: Pixmaps
    text: string
}

interface PixmapButtonProps extends PixmapProps {
    onClick: MouseEventHandler<any>
    disabled?: boolean
}

interface PixmapLinkProps extends PixmapProps {
    to: string
}

export class Pixmap extends React.Component<PixmapProps, any> {
    render() {
        return <>
            <img
                className="pixmapIcon"
                alt={this.props.pixmap + " icon"}
                src={"/icons/" + this.props.pixmap + ".svg"}
                width="32"
                height="32"/>
            <span className="pixmapText">{this.props.text}</span>
        </>
    }
}

export class PixmapButton extends React.Component<PixmapButtonProps, any> {
    render() {
        return (
            <button onClick={this.props.onClick} disabled={this.props.disabled} className="pixmapButton">
                <Pixmap {...this.props} />
            </button>
        )
    }
}

export class PixmapLink extends React.Component<PixmapLinkProps, any> {
    render() {
        return (
            <Link className="pixmapLink" to={this.props.to}>
                <Pixmap {...this.props} />
            </Link>
        )
    }
}

export class PixmapGroup extends React.Component<any, any> {
    render() {
        return <div className="pixmapGroup">
            {this.props.children}
        </div>
    }
}