import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route} from "react-router-dom";
import {PoolOverview} from "./components/pools/PoolOverview";
import {PoolDetails} from "./components/pools/PoolDetails";
import {PoolAddCard} from "./components/pools/PoolAddCard";
import {PoolAdd} from "./components/pools/PoolAdd";
import {Index} from "./components/pools/Index";
import {AuthButton, Login, PrivateRoute} from "./components/Login";
import {checkLogin} from "./backend/CheckLogin";
import {LoggedInObservable} from "./interfaces/state";
import {PoolViewCards} from "./components/pools/PoolViewCards";
import {TrainOverview} from "./components/train/TrainOverview";
import {TrainDo} from "./components/train/TrainDo";
import {PoolEdit} from "./components/pools/PoolEdit";

function App() {
    checkLogin().then(value => LoggedInObservable.setValue(value));
    return (
        <div className="page">
            <Router>
                <div className="loginBanner">
                    <AuthButton/>
                </div>
                <Route path="/login" exact component={Login}/>
                <PrivateRoute path="/" exact component={Index}/>
                <PrivateRoute path="/pools" exact component={PoolOverview}/>
                <PrivateRoute path="/pools/new" exact component={PoolAdd}/>
                <PrivateRoute path="/pool/:id" exact component={PoolDetails}/>
                <PrivateRoute path="/pool/:id/add" exact component={PoolAddCard}/>
                <PrivateRoute path="/pool/:id/edit" exact component={PoolEdit}/>
                <PrivateRoute path="/pool/:id/view" exact component={PoolViewCards}/>
                <PrivateRoute path="/pool/:id/train" exact component={TrainOverview}/>
                <PrivateRoute path="/pool/:id/train/do" exact component={TrainDo}/>
            </Router>
        </div>
    );
}

export default App;
