import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import React from "react";
import {
    InitializationResult,
    initializeTraining,
    reinitializeTraining
} from "../../backend/training/InitializeTraining";
import {PixmapButton, PixmapGroup} from "../Pixmap";
import {ToggleButton} from "../ToggleButton";

interface TrainOverviewProps {
    id: string
}

export type Order = "RANDOM" | "AGE" | "AGE_REVERSED" | "NUMTRIES" | "NUMTRIES_REVERSED";

interface TrainOverviewState {
    order: Order,
    useUnknown: boolean,
    useKnown: boolean,
    useSuccess: boolean,
    initializationResult?: InitializationResult,
    back: boolean

}

class TrainOverviewClazz extends React.Component<RouteComponentProps<TrainOverviewProps>, TrainOverviewState> {
    constructor(props: Readonly<RouteComponentProps<TrainOverviewProps>>) {
        super(props);
        this.state = {
            order: "AGE",
            useUnknown: true,
            useKnown: true,
            useSuccess: true,
            initializationResult: undefined,
            back: false
        }
    }

    render() {
        if (this.state.back) {
            return <Redirect to={"/pool/" + this.props.match.params.id}/>
        }

        return (
            <div className="pageContent">
                <div className="pageHeader">
                    <PixmapButton onClick={(event) => this.back()} pixmap="back" text="Zurück zur Lektion"/>
                </div>
                <h1>Einstellungen:</h1>
                <p>
                    <label htmlFor="order">Reihenfolge:</label><br/>
                    <select name="order" onChange={(event => this.selectOrder(event))} value={this.state.order}>
                        <option value="RANDOM">Zufällig</option>
                        <option value="AGE">Alt &gt; Neu</option>
                        <option value="AGE_REVERSED">Neu &gt; Alt</option>
                        <option value="NUMTRIES">Nach Versuchen aufsteigend</option>
                        <option value="NUMTRIES_REVERSED">Nach Versuchen absteigend</option>
                    </select>
                </p>
                <p>
                    Verwende die folgenden Karten:
                </p>
                <ToggleButton pushed={this.state.useSuccess} text="Erfolgreich"
                              onChange={(checked) => this.setSuccessState(checked)}/>
                <ToggleButton pushed={this.state.useKnown} text="Bekannt"
                              onChange={(checked) => this.setKnownState(checked)}/>
                <ToggleButton pushed={this.state.useUnknown} text="Unbekannte"
                              onChange={(checked) => this.setUnknownState(checked)}/>
                <br/>
                {this.getErrorMessage()}
                <PixmapGroup>
                    <PixmapButton onClick={(event) => this.initializeTraining(event)} pixmap="brain" text="Lernen"/>
                    <PixmapButton onClick={(event) => this.reinitializeTraining(event)} pixmap="forward"
                                  text="Lernen wiederaufnehmen"/>
                </PixmapGroup>
                <div className="pageFooter">
                    <PixmapButton onClick={(event) => this.back()} pixmap="back" text="Zurück zur Lektion"/>
                </div>
            </div>
        )
    }

    private getErrorMessage() {
        if (this.state.initializationResult !== undefined) {
            switch (this.state.initializationResult) {
                case InitializationResult.SUCCESS:
                    return <Redirect to={"/pool/" + this.props.match.params.id + "/train/do"}/>
                case InitializationResult.UNKNOWN_FAILURE:
                    return <span className="error">Unbekannter Fehler<br/></span>;
                case InitializationResult.NO_USAGE_SELECTED:
                    return <span className="error">Mindestens eine Gruppe muss ausgewählt werden<br/></span>;
                case InitializationResult.NO_CARDS_AVAILABLE:
                    return <span className="error">Die Auswahl enthält keine Karten<br/></span>;
            }
        }
        return "";
    }

    private selectOrder(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({
            order: event.target.value as Order
        })
    }

    private setUnknownState(checked: boolean) {
        this.setState({
            useUnknown: checked
        })
    }

    private setKnownState(checked: boolean) {
        this.setState({
            useKnown: checked
        })
    }

    private setSuccessState(checked: boolean) {
        this.setState({
            useSuccess: checked
        })
    }

    private initializeTraining(event: React.MouseEvent<HTMLInputElement>) {
        initializeTraining(
            this.props.match.params.id,
            this.state.order,
            this.state.useUnknown,
            this.state.useKnown,
            this.state.useSuccess
        ).then(value => this.setState({initializationResult: value}))
    }

    private reinitializeTraining(event: React.MouseEvent<HTMLInputElement>) {
        reinitializeTraining().then(value => this.setState({initializationResult: value}))
    }

    private back() {
        this.setState({back: true})
    }
}

export const TrainOverview = withRouter(TrainOverviewClazz);